import { courseUsers } from 'API/course'
import { navigate, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React from 'react'
import { UserType } from 'types/UserAdditionMetaData'

const People: React.FC<PageProps> = ({ params: { id: course_id } }) => {
	const [users, setUsers] = React.useState<{
		students: {
			user: UserType
		}[]
		teachers: {
			user: UserType
		}[]
	}>({
		students: [],
		teachers: [],
	})
	const { ShowDetails, viewAs } = useGetCourseDetails(course_id)
	React.useEffect(() => {
		const run = async () => {
			const users = await courseUsers(course_id)
			if (users) {
				setUsers(users)
			}
		}
		run()
	}, [])

	React.useEffect(() => {
		if (viewAs === 'Student') navigate('/404')
	}, [viewAs])
	return (
		<>
			{ShowDetails}
			<div className='p2'>
				<h2 className='py-2 '>Teachers</h2>
				{UsersTable(users.teachers)}
			</div>

			<div className='p2'>
				<h2 className='py-2 '>Students</h2>
				{UsersTable(users.students)}
			</div>
		</>
	)
}

export default People

function UsersTable(
	users: {
		user: UserType
	}[]
) {
	return (
		<table className='table'>
			<thead>
				<tr>
					<th scope='col'>#</th>
					<th scope='col'>First</th>
					<th scope='col'>Last</th>
					<th scope='col'>Email</th>
					<th scope='col'>Logs</th>
				</tr>
			</thead>
			<tbody>
				{users?.map((elem, index) => {
					return (
						<>
							<tr>
								<th scope='row'>{index + 1}</th>
								<td>{elem.user.first_name}</td>
								<td>{elem.user.last_name}</td>
								<td>{elem.user.email}</td>
								<td>
									<div
										className='btn btn-primary'
										onClick={() => {
											navigate('../logs/' + elem.user.uid)
										}}
									>
										Logs
									</div>
								</td>
							</tr>
						</>
					)
				})}
			</tbody>
		</table>
	)
}
